import isObjectEmpty from './is-object-empty';

export const dedupe = <T>(a: T[]) => [...new Set(a)];

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunked: T[][] = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
};

export const createFilledArray = <T>(length: number, fill: T): T[] =>
  new Array(length).fill(fill);

export const findFirstSelectedItem: <T extends { isSelected: boolean }>(
  collection: T[]
) => T | undefined = collection => collection.find(item => !!item.isSelected);

// any used here as collections parsed are not necessarily homogenous
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeNullishAndEmptyValues = (collection: any[]): any[] =>
  collection.filter(value => {
    if (value == null) return false;
    const type = typeof value;
    let flag = false;
    switch (type) {
      case 'number':
        flag = true;
        break;
      case 'string':
        flag = value.length > 0;
        break;
      case 'object':
        flag = !isObjectEmpty(value);
        break;
    }
    return flag;
  });

export const joinNonEmptyStrings: (
  collection: (string | undefined | null)[],
  glue?: string,
  andLastItem?: boolean
) => string = (collection, glue = ', ', andLastItem = false): string => {
  let newCollection = removeNullishAndEmptyValues(collection);
  if (andLastItem && newCollection.length > 1) {
    const lastItem = newCollection.pop();
    newCollection = [newCollection.join(glue), 'and', lastItem];
  }
  return newCollection.join(glue);
};

export const splitArray = <T>(
  array: T[],
  condition: (item: T, idx: number) => boolean
) => {
  const { trueHalf, falseHalf } = array.reduce(
    (result, item, idx) => {
      condition(item, idx)
        ? result.trueHalf.push(item)
        : result.falseHalf.push(item);
      return result;
    },
    { trueHalf: [] as T[], falseHalf: [] as T[] }
  );
  return [trueHalf, falseHalf];
};
