import { css, Theme } from '@emotion/react';
import styled from 'components/styled';
import { Box } from 'components/box';
import { PillVariantsEnum } from 'types/types';

interface PillProps {
  backgroundColor?: string;
  color?: string;
  variant?: PillVariantsEnum;
  noWrap?: boolean;
  borderColor?: string;
}

export const Pill = styled(Box)<PillProps>`
  ${({
    backgroundColor: namedBackgroundColor,
    color: namedColor,
    variant,
    noWrap,
    theme,
  }: { theme: Theme } & PillProps) => {
    let color = namedColor ? theme.colors[namedColor] : theme.colors.darkGrey;
    let backgroundColor = namedBackgroundColor
      ? theme.colors[namedBackgroundColor]
      : theme.colors.lightishGrey;
    let borderColor: string | null = null;

    switch (variant) {
      case PillVariantsEnum.highlighted:
        color = theme.colors.white;
        backgroundColor = theme.colors.accent;
        break;
      case PillVariantsEnum.information:
        color = theme.colors.white;
        backgroundColor = theme.colors.information;
        break;
      case PillVariantsEnum.warning:
        color = theme.colors.white;
        backgroundColor = theme.colors.warning;
        break;
      case PillVariantsEnum.yellow:
        color = theme.colors.white;
        backgroundColor = theme.colors.yellow;
        break;
      case PillVariantsEnum.grey:
        color = theme.colors.white;
        backgroundColor = theme.colors.subtleText;
        break;
      case PillVariantsEnum.muted:
        color = theme.colors.darkestGrey;
        backgroundColor = theme.colors.white;
        break;
      case PillVariantsEnum.info:
        color = theme.colors.black;
        backgroundColor = theme.colors.background;
        borderColor = theme.colors.blue;
        break;
      case PillVariantsEnum.red:
        color = theme.colors.white;
        backgroundColor = theme.colors.pillRed;
    }

    return css`
      font-family: ${theme.fonts.header};
      font-size: 10px;
      line-height: 11px;
      font-weight: ${theme.fontWeights.bold};
      padding: ${borderColor ? '3px 8px' : '4px 9px'};
      letter-spacing: 0.02rem;
      border-radius: 20px;
      text-transform: uppercase;
      text-align: center;
      background-color: ${backgroundColor};
      color: ${color};
      display: inline-block;
      ${borderColor &&
      css`
        border: 1px solid ${borderColor};
      `}
      ${noWrap &&
      css`
        white-space: nowrap;
      `}

      @media ${theme.mediaQueries.tabletUp} {
        font-size: 9px;
        line-height: 12px;
        padding: ${borderColor ? '2px 5px' : '3px 6px'};
      }
    `;
  }}
`;

export default Pill;
