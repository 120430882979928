import intlFormats from '../../shared/intlFormats';
import { Config } from 'types/interface';
import { CURRENCY_CODE } from '../../constants';

interface PriceProps {
  value?: number;
  currencyCode?: string;
  hideSymbol?: boolean;
}

const formatValue = (value, format) => {
  if (format.rate) {
    value *= format.rate;
  }
  const parts = Math.abs(value).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, format.groupDelim);
  if (1 in parts) {
    parts[1] = parts[1].padEnd(2, '0');
  }
  return parts.join('.');
};

const Price = ({ value, currencyCode, hideSymbol = false }: PriceProps) => {
  const format = intlFormats[currencyCode || CURRENCY_CODE];

  if (!value && value !== 0) {
    return null;
  }

  return (
    <>
      {value < 0 && '-'}
      {!hideSymbol && (format.symbol || currencyCode)}
      {formatValue(value, format)}
    </>
  );
};

export default Price;
