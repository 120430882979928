import {
  BundleProduct,
  ConfigurableProduct,
  CustomizableProductInterface,
  CustomizableOptionInterface,
  CustomizableOptionValuesInterface,
  GroupedProduct,
  PhysicalProductInterface,
  ProductGalleryExternalVideo,
  ProductGalleryItemInterface,
  ProductGalleryItemTypeEnum,
  ProductInterface,
  SimpleProduct,
  VirtualProduct,
  CustomizableOptionValueInterface,
} from 'types/types';

type Product =
  | BundleProduct
  | ConfigurableProduct
  | GroupedProduct
  | PhysicalProductInterface
  | ProductInterface
  | SimpleProduct
  | VirtualProduct;

export const isPhysical = (item: Product): item is PhysicalProductInterface =>
  typeof (item as PhysicalProductInterface).condition === 'string' &&
  typeof (item as PhysicalProductInterface).isParallelImport === 'boolean' &&
  typeof (item as PhysicalProductInterface).isFreeShipping === 'boolean';

export const isConfigurable = (
  product: Product
): product is ConfigurableProduct =>
  Array.isArray((product as ConfigurableProduct).variants) &&
  !!(product as ConfigurableProduct).variants.length &&
  Array.isArray((product as ConfigurableProduct).configurableOptions) &&
  !!(product as ConfigurableProduct).configurableOptions.length;

const isCustomizableOption = (
  option: CustomizableOptionInterface
): option is CustomizableOptionInterface =>
  typeof (option as CustomizableOptionInterface).id === 'string' &&
  typeof (option as CustomizableOptionInterface).label === 'string' &&
  typeof (option as CustomizableOptionInterface).position === 'number';

export const isCustomizable = (
  product: Product
): product is CustomizableProductInterface =>
  Array.isArray(
    (product as CustomizableProductInterface).customizableOptions
  ) &&
  !!(product as CustomizableProductInterface).customizableOptions.length &&
  (product as CustomizableProductInterface).customizableOptions.every(option =>
    isCustomizableOption(option)
  );

const isCustomizableOptionValue = (
  value: CustomizableOptionValueInterface
): value is CustomizableOptionValueInterface =>
  typeof value === 'object' &&
  typeof (value as CustomizableOptionValueInterface).id === 'string' &&
  typeof (value as CustomizableOptionValueInterface).label === 'string' &&
  typeof (value as CustomizableOptionValueInterface).price === 'number' &&
  typeof (value as CustomizableOptionValueInterface).isSoldOut === 'boolean' &&
  typeof (value as CustomizableOptionValueInterface).position === 'number';

export const isCustomizableOptionWithValues = (
  option: CustomizableOptionInterface
): option is CustomizableOptionValuesInterface =>
  Array.isArray((option as CustomizableOptionValuesInterface).values) &&
  !!(option as CustomizableOptionValuesInterface).values.length &&
  (option as CustomizableOptionValuesInterface).values.every(value =>
    isCustomizableOptionValue(value)
  );

export const isGalleryImage = (
  gallery: ProductGalleryExternalVideo | ProductGalleryItemInterface
): gallery is ProductGalleryItemInterface => {
  return (
    (gallery as ProductGalleryItemInterface).type ===
    ProductGalleryItemTypeEnum.Image
  );
};

export const isGalleryVideo = (
  gallery: ProductGalleryExternalVideo | ProductGalleryItemInterface
): gallery is ProductGalleryExternalVideo => {
  return (
    (gallery as ProductGalleryExternalVideo).type ===
    ProductGalleryItemTypeEnum.ExternalVideo
  );
};
