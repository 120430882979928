import { ProductSaving, SavingsFormatEnum } from 'types/types';
import { memo } from 'react';
import { css } from '@emotion/react';
import { Flex, FlexProps } from 'components/box';

const borderRadiusIdxMap = {
  0: 1,
  1: 2,
  2: 2,
};

const borderRadius = (radius?: boolean[]) =>
  radius
    ? radius.map((r, idx) => (r ? borderRadiusIdxMap[idx] : 0))
    : undefined;

type SavingsBadgeProps = FlexProps & {
  className?: string;
  saving?: ProductSaving;
  isLarge?: boolean[];
  isAccented?: boolean[];
  borderRadiusTopRight?: boolean[];
  borderRadiusTopLeft?: boolean[];
  borderRadiusBottomRight?: boolean[];
  borderRadiusBottomLeft?: boolean[];
  alwaysSmall?: boolean;
};

const SavingsBadge = memo(
  ({
    className,
    saving,
    isLarge = [false, false, false],
    isAccented = [],
    borderRadiusTopRight,
    borderRadiusTopLeft,
    borderRadiusBottomRight,
    borderRadiusBottomLeft,
    alwaysSmall,
    ...restProps
  }: SavingsBadgeProps) => {
    if (!saving?.fixed.value) {
      return null;
    }

    const savingsText =
      saving.format === SavingsFormatEnum.Fixed
        ? saving.fixed.formattedValue
        : `${saving.percent}%`;

    return (
      <Flex
        className={className}
        backgroundColor={
          isAccented
            ? isAccented.map((a, idx) => (idx === 0 || a ? 'accent' : 'black'))
            : undefined
        }
        {...(alwaysSmall
          ? {
              mt: '6%',
              mr: 0,
              py: '0.5em',
              px: 2,
              borderTopLeftRadius: 1,
              borderBottomLeftRadius: 1,
            }
          : {
              mt: ['6%', null, 0],
              mr: [0, null, '5%'],
              py: ['0.5em', null, 0],
              px: [2, '1em', 0],
              borderTopLeftRadius: borderRadius(borderRadiusTopLeft),
              borderTopRightRadius: borderRadius(borderRadiusTopRight),
              borderBottomRightRadius: borderRadius(borderRadiusBottomRight),
              borderBottomLeftRadius: borderRadius(borderRadiusBottomLeft),
            })}
        fontFamily="header"
        fontWeight={700}
        fontSize="s"
        color="white"
        textAlign="center"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...restProps}
        css={theme => css`
          position: absolute;
          top: 0;
          right: 0;
          z-index: 130;
          transition: all 0.3s ease;
          text-transform: uppercase;

          ${!alwaysSmall &&
          css`
            @media ${theme.mediaQueries.desktopUp} {
              min-width: 45px;
              height: 45px;

              ${isAccented[2] &&
              css`
                min-width: 56px;
                height: 56px;
              `}
            }
          `}

          &:hover {
            background-color: ${theme.colors.accent} !important;
          }
        `}
      >
        <div
          css={theme => css`
            display: none;
            ${!alwaysSmall &&
            css`
              @media ${theme.mediaQueries.desktopUp} {
                display: block;
                ${
                  isAccented[2]
                    ? css`
                        font-size: ${theme.fontSizes.r};
                      `
                    : css`
                        font-size: ${theme.fontSizes.s};
                      `
                }
            `}
          `}
        >
          save
        </div>
        <div>
          <span
            css={theme => css`
              font-size: ${theme.fontSizes.s};
              display: inline-block;
              margin-right: 2px;
              ${isAccented[1] &&
              css`
                padding: ${theme.space[2]}px 0;
              `}

              @media ${theme.mediaQueries.desktopUp} {
                display: none;
              }
            `}
          >
            -
          </span>
          <span
            css={theme => css`
              padding: 0;
              font-size: ${theme.fontSizes.s};

              ${isLarge[0] &&
              css`
                font-size: ${theme.fontSizes.r};
              `}

              ${isAccented[1] &&
              css`
                @media ${theme.mediaQueries.tabletOnly} {
                  font-size: ${theme.fontSizes.r};
                }
              `}

              @media ${theme.mediaQueries.desktopUp} {
                padding: 0 ${theme.space[1]}px;
                font-size: ${theme.fontSizes.r};
                ${isAccented[2] &&
                css`
                  font-size: ${theme.fontSizes.m};
                `}
              }
            `}
          >
            {savingsText}
          </span>
        </div>
      </Flex>
    );
  }
);

export default SavingsBadge;
