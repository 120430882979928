import formatUrl from '../../../../util/make-url';
import { Box } from 'components/box';
import { css } from '@emotion/react';
import Head from 'next/head';

// NOTE: direct usage of the theme is discouraged in most circumstances.
// DO NOT emulate the following without understanding what you're doing.
import { breakpointsPx } from 'themes/onedayonly';

export const mediaBreakpoints = [
  `(max-width: ${breakpointsPx[0] - 1}px)`,
  `(max-width: ${breakpointsPx[1] - 1}px)`,
];

const getUrl = (url: string, width: number) =>
  formatUrl(url, {
    width,
    height: width,
    bg: 'fff',
    fit: 'fill',
  });

const imgAttrs = ({
  url,
  widths,
  sizes,
}: {
  url: string;
  widths: number[];
  sizes: string[];
}) => ({
  src: getUrl(url, Math.max(...widths)), // we want to use the biggest image as the default src
  srcSet: widths.map(width => `${getUrl(url, width)} ${width}w`).join(', '),
  sizes: sizes.join(', '),
});

const ResponsiveImage = ({
  title,
  url,
  widths,
  sizes,
  preload,
  altText,
}: {
  title: string;
  url: string;
  widths: number[];
  sizes: string[];
  preload?: boolean;
  altText?: string;
}) => {
  const attrs = imgAttrs({ url, widths, sizes });

  /**
   * NOTE: imagesizes prop is not in the link element types for react 18 even though it gets passed through correctly.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#attr-imagesizes
   */
  const linkProps = {
    imageSrcSet: attrs.srcSet,
    imageSizes: attrs.sizes,
  };

  return (
    <>
      {preload && (
        <Head>
          <link
            as="image"
            rel="preload"
            href={attrs.srcSet ? undefined : attrs.src}
            {...linkProps}
            key={`responsive-image-${url}-${widths.join('-')}-${sizes.join(
              '-'
            )}`}
          />
        </Head>
      )}
      <img
        aria-label={altText}
        tabIndex={-1}
        title={title}
        alt={title}
        className="image"
        {...attrs}
        css={css`
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          display: block;
          max-width: 100%;
          max-height: 100%;
          min-width: 100%;
          opacity: 1;
          transition-duration: 512ms;
          transition-property: opacity, visibility;
          transition-timing-function: ease-out;
          visibility: visible;
        `}
      />

      {/*  Overlay to prevent the user from right-clicking and save the image */}
      <Box
        zIndex={10}
        position="absolute"
        top="0"
        left="0"
        minWidth="100%"
        minHeight="100%"
      />
    </>
  );
};

export default ResponsiveImage;
