const intlFormats = {
  USD: {
    symbol: '$',
    decimal: '.',
    groupDelim: ',',
  },
  GBP: {
    symbol: '£',
    decimal: '.',
    groupDelim: ',',
  },
  EUR: {
    symbol: '€',
    decimal: '.',
    groupDelim: ',',
  },
  ZAR: {
    symbol: 'R',
    decimal: '.',
    groupDelim: ',',
  },
  EBUCKS: {
    symbol: '',
    decimal: '.',
    groupDelim: ' ',
    rate: 10,
  },
};

export default intlFormats;
