/**
 * Returns whether the given object is empty ({}).
 * @see https://stackoverflow.com/a/32108184.
 *
 * @param {Object} obj - the object under test.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function isObjectEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
